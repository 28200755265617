import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const TabPage = ({
  tabArray,
  activeTab = 0,
  setActive = () => { },
}) => {
  const navigate = useNavigate();

  const changePage = (nav) => {
    if (nav && (!setActive || setActive === null)) return navigate(nav)
    return setActive(nav)
  }

  return (
    <Row>
      <div className="tab-action">
        <div className="left">
          {tabArray.map((val, index) => {
            return (
              <div key={val.key || index} className={activeTab === index ? "active" : ""} onClick={() => changePage(val.nav)}>
                <h3 className='p-15'>{val.title}</h3>
              </div>
            )
          })}
        </div>
      </div>
    </Row>
  );
};

export default TabPage;
